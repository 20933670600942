import { BOOTH_BASE_FOR_ENTITY_LIST_FRAGMENT } from '@/graphql/_Fragments/Booth/BaseForEntityList';
import { EXHIBIT_HALL_BASE_FRAGMENT } from '@/graphql/_Fragments/ExhibitHall/Base';
import { SESSION_FOR_GEOZONE_FRAGMENT } from '@/graphql/_Fragments/Session/ForGeozone';

export const GEOZONE_ENTITY_LIST_SEARCH_FRAGMENT = `
  fragment geozoneEntityListSearchFragment on Geozone {
    id
    uid
    __typename
    schemaCode
    foreignId
    name
    shortName
    objectType
    exhibitHall {
      ...exhibitHallBaseFragment
    }
    booth {
      ...boothBaseForEntityListFragment
    }
    sessions {
      ...sessionForGeozoneFragment
    }
    score: _score
  }
  ${BOOTH_BASE_FOR_ENTITY_LIST_FRAGMENT}
  ${SESSION_FOR_GEOZONE_FRAGMENT}
  ${EXHIBIT_HALL_BASE_FRAGMENT}
`;
